<template>
  <div class="actDataDetailBody">
    <PocCardTable class="actDataDetail">
<!--      <template v-slot:headerTitle>Act Repo</template>-->
<!--      <template v-slot:headerGroup>-->
<!--        <el-button @click="goback">返回</el-button>-->
<!--        <el-button @click="download" type="primary">下载</el-button>-->
<!--        <el-button @click="search" type="primary">查询</el-button>-->
<!--      </template>-->

      <template>
        <div class="flexBetw opeartionBox">
          <div class="pcth-title-left">
          Payroll Act<span v-html="'&nbsp;'"></span>
          <span style="color: #303133; font-weight: 500">/<span v-html="'&nbsp;'"></span>Act Repo</span></div>
          <div>
            <el-button @click="goback">返回</el-button>
            <el-button @click="download" type="primary">下载</el-button>
            <el-button @click="search('search')" type="primary">查询</el-button>
          </div>
        </div>
      </template>

      <template>
        <el-form
            :inline="true"
            label-position="top"
            :model="formInline"
            class="personnelRequisitionForm"
          >
            <el-form-item label="HFM Code">
              <el-select
                v-model="formInline.hfmCode"
                clearable
                filterable
                placeholder="请选择"
                @change="hfmCodeChange"
                @clear="hfmCodeClear"
              >
                <el-option
                  v-for="item in hfmCodes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Bussiness Unit">
              <el-input placeholder="" v-model="formInline.bussinessUnit" :disabled=true>
              </el-input>
            </el-form-item>

            <el-form-item label="Local/Expat">
              <el-select
                v-model="formInline.localExpat"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in localExpats"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Location" label-position="top">
              <el-input placeholder="" v-model="formInline.location">
              </el-input>
            </el-form-item>

            <el-form-item label="CS ID" label-position="top">
              <el-input placeholder="" v-model="formInline.csId"> </el-input>
            </el-form-item>

            <el-form-item label="Chinese Name" label-position="top">
              <el-input placeholder="" v-model="formInline.chineseName">
              </el-input>
            </el-form-item>
          </el-form>
      </template>
    </PocCardTable>
    <PocCardTable class="detailPageContainer">
      <PocTable
        ref="tTableRef"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        stripe
        size="large"
        :max-height="maxHeight"
        @selection-change="handleSelectionChange"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        @row-click="rowClick"
        @select-all="selectAll"
        highlight-current-row
      >
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          :align="item.align"
          show-overflow-tooltip
        ></el-table-column>
      </PocTable>
    </PocCardTable>
  </div>
</template>

<script>
import { ActDataDetailTableColumn } from '@/views/payroll/TableColumn'
import PersonnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'
import { actFileExport } from '@/api/payrollReport'
import {
  payrollActList,
  getEntityHfmCode,
  getBusinessUnitNameByHfmCode
} from '@/api/payroll'

import PocSlider from '@/components/poc-slider/poc-slider'
const { Message } = $PCommon
export default {
  name: 'actData',
  mixins: [$PCommon.TableMixin],
  components: {
    PocSlider
  },

  data() {
    return {
      loading: false,
      columnConfig: ActDataDetailTableColumn,
      tableData: [],
      pageConfig: {
        total: 1,
        pageNum: 1
      },
      formInline: {
        hfmCode: '',
        bussinessUnit: '',
        localExpat: '',
        location: '',
        csId: '',
        chineseName: '',
        pageSize: 10,
        pageNum: 1,
        periodId: ''
      },
      hfmCodes: [],
      localExpats: [
        {
          label: 'Local',
          value: 'Local'
        },
        {
          label: 'Expat',
          value: 'Expat'
        }
      ],
      ruleForm: {},
      targetObj: '',
      toObj: '',
      periodDialogVisible: false,
      formStatus: false,
      periodRange: '',
      isClear: false
    }
  },
  computed: {},
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.formInline.periodId = this.$route.query.id
    this.maxHeight = h - 330
    this.queryApi()
    this.getFormLineData()
  },
  mounted() {},
  methods: {
    async getFormLineData() {
      getEntityHfmCode().then((res) => {
        this.hfmCodes = res.data
      })
    },

    hfmCodeChange(v) {
      const data = {
        hfmCode: this.formInline.hfmCode
      }

      getBusinessUnitNameByHfmCode(data).then((res) => {
        this.formInline.bussinessUnit = res.data[0].label
        if (this.isClear == true) {
          this.formInline.bussinessUnit=''
          this.isClear = false
        }
      })
    },

    hfmCodeClear() {
      this.isClear = true
    },

    search(type = 'normal') {
      type == 'search' ? this.formInline.pageNum = 1 : '';
      payrollActList(this.formInline).then((res) => {
        if(res.data){
          this.tableData = res.data.list
          this.tableData.forEach((item) => {
            if (item.startDate != null) {
              item.startDate = item.startDate.slice(0, 10)
            }
          })
          this.pageConfig.total = res.data.total
        }else {
          this.tableData = []
        }
      })
    },

    queryApi() {
      this.search()
    },

    selectPersonal(val1, val2, val3) {
      if (!this.formStatus) {
        this.targetObj = val1
        this.toObj = val2
        this.$refs.PersonnelSelectionDialogRef.show({
          type: val3
        })
      }
    },

    createPeriod() {
      this.periodDialogVisible = true
    },

    handleSelectionChange() {},

    pageSizeChange(val) {
      this.formInline.pageSize = val
      this.queryApi()
    },

    pageChange(val) {
      this.formInline.pageNum = val
      this.queryApi()
    },

    rowClick() {},

    selectAll() {},

    handleClose() {},

    onEdit(val) {},

    download() {
      actFileExport(this.formInline);
    },

    goback() {
      this.$router.push({
        path: '/pcTracker/payroll'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pcth-title-left {
  width: 240px;
  height: 29px;
  font-size: 21px;
  font-weight: 400;
  color: #868686;
  line-height: 29px;
}
.actDataDetailBody {
  overflow-x: hidden;
  .flexBetw {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .el-form .el-form-item {
    margin: 16px 16px 0 0 !important;
  }
  .actDataDetail {
    .topTitle {
      display: flex;
      justify-content: space-between;
    }
  }
}
.detailPageContainer{
  margin-top: 16px;
}
::v-deep .detailPageContainer .el-card__body{
  padding: 20px !important;
}
::v-deep .el-form .el-form-item{
  margin-right: 16px !important;
  margin-bottom: 0 !important;
}
</style>
